import React, { useEffect, useState } from 'react';
import axios from 'axios';

import config from '../../config';

const LeaderBoard = ( {game} ) => {
  const [leaderBoard, setLeaderBoard] = useState([]);

  useEffect(() => {
    axios.get(`${config.API_BASE_URL}/minigames/leaderboard-${game}/`)
      .then(response => {
        const sortedData = response.data.sort((b, a) => b.score - a.score);
        setLeaderBoard(sortedData);
      })
      .catch(error => {
        console.error("There was an error fetching the leaderboard!", error);
      });
  }, []);

  // useEffect(() => {
  //   // console.log(leaderBoard)
  // }, [leaderBoard]);

  return (
    <div>
      <h1>Leaderboard</h1>
      <ul>
        {leaderBoard.map((entry, index) => (
          <li key={index}>
            {entry.player_name} - {entry.score} (achieved at: {new Date(entry.achieved_at).toLocaleString()})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeaderBoard;
