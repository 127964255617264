import {useContext} from 'react';
import './LandingPage.css'
import { SoundContext } from './Utils/SoundProvider';


function LandingPage({ setLandingPage }) {
  const { isPlaying, setIsPlaying, volume, analyser } = useContext(SoundContext);


  const handle_yes = () =>{
    setLandingPage(false)
    setIsPlaying(true)
  }

    return (
        <div>
            <h1 className='title-landing'>Do you want to visit the site with music?</h1>
            <div className='button-container-landing'>
            <button onClick={() => setLandingPage(false)}>No</button>
            <button onClick={() => handle_yes()}>Yes</button>
            </div>
        </div>
    );
}

export default LandingPage;
