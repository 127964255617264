import {useContext} from "react";

import { IconButton, useColorMode, Box } from '@chakra-ui/react';
import { FaSun, FaMoon } from 'react-icons/fa';
import CubeScene from './Components/menucube/CubeScene';
import HomePage from './Components/home/HomePage';
import ProjectsList from './Components/projects/ProjectList';
import GameMenu from './Components/minigames/GameMenu';
import AboutMe from './Components/aboutMe/AboutMe';
 
import config from './config';
import SoundButton from './Utils/SoundButton';
import AudioVisualizer from './Utils/AudioVisualizer';
import { SoundContext } from './Utils/SoundProvider';
import { MenuProvider, MenuContext } from './Utils/MenuProvider';
import ScrollingBackground from './Components/aboutMe/ScrollingBackground';

const MainApp = ({currentCategory, colorMode, toggleColorMode, handleCurrentCategoryChange, renderComponent, weather}) => {
  
  const { 
    menuCubeOn,
    setMenuCubeOn,
    menuArrowsOn,
    setMenuArrowsOn,
    menuButtonOn,
    setMenuButtonOn, } = useContext(MenuContext);

  return (
    <Box className="App" position="relative" minH="100vh" transitionProperty="background-image" transitionDuration="200ms" transitionTimingFunction="ease-in-out">
      <div className={currentCategory !== 'Menu' ? 'soundButtonHider' : 'soundButton'}>
        <SoundButton></SoundButton>
      </div>
      <AudioVisualizer></AudioVisualizer>
      <IconButton position="absolute" top={4} right={4} icon={colorMode === 'light' ? <FaMoon /> : <FaSun />} onClick={toggleColorMode} aria-label="Toggle color mode" zIndex={9999} />
      <CubeScene onCategoryChange={handleCurrentCategoryChange} />
      {currentCategory === 'Home' && renderComponent && <HomePage weather={weather} />}
      {currentCategory === 'Projects' && renderComponent && <ProjectsList />}
      {currentCategory === 'About Me' && renderComponent && <div>
        <AboutMe></AboutMe>
      </div>}
      {currentCategory === 'Mini Games' && renderComponent && <GameMenu />}
    </Box>
  );
}

export default MainApp
  