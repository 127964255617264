import MainApp from './MainApp';
// src/App.js
import './App.css'; // Ensure App.css does not override transitions
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {Box, useColorMode } from '@chakra-ui/react';
 
import config from './config';
import { SoundContext } from './Utils/SoundProvider';
import LandingPage from './LandingPage'

function App() {
  const {isSound, setIsSound, isPlaying} = useContext(SoundContext);
  const [currentCategory, setCurrentCategory] = useState('Menu');
  const [renderComponent, setRenderComponent] = useState(false);
  const [weather, setWeather] = useState(null);
  const {colorMode, toggleColorMode} = useColorMode();
  const [showVisualizer, setShowVisualizer] = useState(false);
  const [landingPage, setLandingPage] = useState(true)


  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/home/current-weather/`);
      setWeather(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Use a timeout to delay showing the AudioVisualizer
  useEffect(() => {
    if (isPlaying) {
      // Set a timeout to delay rendering AudioVisualizer
      const timer = setTimeout(() => {
        setShowVisualizer(true);
      }, 1000); // Delay in milliseconds (e.g., 1000ms = 1 second)

      return () => clearTimeout(timer); // Clean up the timeout on unmount or when isPlaying changes
    } else {
      setShowVisualizer(false); // Hide visualizer when not playing
    }
  }, [isPlaying]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currentCategory === 'Menu') {
      setRenderComponent(false);
    } else {
      setTimeout(() => {
        setRenderComponent(true);
      }, 500);
    }
  }, [currentCategory]);

  const handleCurrentCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  return (
    <div>

      { landingPage && 
      <LandingPage setLandingPage={setLandingPage} />
      }

      { !landingPage &&
      <MainApp currentCategory={currentCategory} colorMode={colorMode} toggleColorMode={toggleColorMode} handleCurrentCategoryChange={handleCurrentCategoryChange} renderComponent={renderComponent} weather={weather} />
      }

    </div>

  );
}

export default App;
