import React from "react";
import './AboutMe.css'; // Import the CSS file


const AboutMe = () => {



    return(
    
        <div className="aboutMe">
            <div>
            I am a developer from Oslo, Norway, thank you for visiting my page!
            </div>
            <div style={{padding: '5%'}}>
            Stay tuned for tech blog about new and exciting technologies.
            </div>
            <div>
            I will be exploring modern IaaS technologies, AI tools, webdesign and more.
            </div>
        </div>
    );
};

export default AboutMe;